/**
 * Copyright © 2016-2023 The Thingsboard Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.tb-autocomplete.tb-quick-links {
  .mat-mdc-option {
    border-bottom: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.76);
    .mat-icon {
      vertical-align: bottom;
      margin-right: 10px;
      color: rgba(0, 0, 0, 0.54);
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }
}
