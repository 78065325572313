/**
 * Copyright © 2016-2023 The Thingsboard Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use '@angular/material' as mat;

$mat-datetimepicker-selected-today-box-shadow-width: 1px;

@mixin mat-datetimepicker-theme($theme) {

  $primary: map-get($theme, primary);

  $primary-color: mat.get-color-from-palette($primary);
  $primary-contrast: mat.get-color-from-palette($primary, default-contrast);

  .mat-datetimepicker-calendar-header {
    background-color: $primary-color;
    color: $primary-contrast;
  }

  .mat-datetimepicker-calendar-body-cell-content {
    &.mat-datetimepicker-calendar-body-selected {
      background-color: $primary-color;
      color: $primary-contrast;
    }

    &.mat-datetimepicker-calendar-body-disabled > .mat-datetimepicker-calendar-body-selected {
      background-color: rgba($primary-color, 0.4);
    }

    &.mat-datetimepicker-calendar-body-today {
      &:not(.mat-datetimepicker-calendar-body-selected) {
        border-color: $primary-color;
      }

      &.mat-datetimepicker-calendar-body-selected {
        box-shadow: inset 0 0 0 $mat-datetimepicker-selected-today-box-shadow-width $primary-contrast;
      }
    }
  }

  .mat-datetimepicker-clock-center {
    background-color: $primary-color;
  }

  .mat-datetimepicker-clock-hand {
    background-color: $primary-color;

    &::before {
      background-color: $primary-color;
    }
  }

  .mat-datetimepicker-clock-cell {
    &.mat-datetimepicker-clock-cell-selected {
      background-color: unquote("hsl(from #{$primary-color} h s calc(l + 5%))");
    }
  }
}
