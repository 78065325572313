/**
 * Copyright © 2016-2023 The Thingsboard Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use '@angular/material' as mat;

@mixin theme-overwrites($primary, $config-or-theme) {
  @include _mat-form-field-overwrites($primary, $config-or-theme);
  @include _mat-button-overwrites($primary, $config-or-theme);
}

@mixin _mat-form-field-overwrites($primary, $config-or-theme) {
  .mat-mdc-form-field.mat-form-field-appearance-fill {
    .mdc-text-field--filled, .mat-mdc-form-field-focus-overlay {
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: mat.get-color-from-palette($primary);
      }
      background-color: transparent;
    }
    .mdc-text-field--filled {
      &:before {
        opacity: 0.03;
      }
      &:not(.mdc-text-field--disabled) {
        &:before {
          opacity: 0.06;
        }
        background-color: transparent;
      }
    }
    .mat-mdc-form-field-focus-overlay {
      &:before {
        opacity: 0.87;
      }
    }
  }
  .mat-mdc-form-field {
    .mat-mdc-form-field-hint-wrapper {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

@mixin _mat-button-overwrites($primary, $config-or-theme) {
  .mat-toolbar {
    .mat-mdc-outlined-button {
      --mdc-outlined-button-outline-color: inherit;
      &:not(:disabled) {
        border-color: var(--mdc-outlined-button-outline-color, inherit);
      }
    }
  }
}
